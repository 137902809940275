// src/components/SVGDots.js
import React from 'react';

const SVGDots = () => (
  <svg 
    version="1.1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    x="0px" 
    y="0px" 
    viewBox="0 0 191.6 1215.4" 
    style={{ enableBackground: 'new 0 0 191.6 1215.4' }} 
    xmlSpace="preserve"
  >
    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
      <path d="M227.7,12788.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C383.7,12801.6,289.7,12808.6,227.7,12788.6z"></path>
      <path d="M1507.7,12788.6c-151-50-253-216-222-362c25-119,136-230,254-255c194-41,395,142,375,339c-11,105-90,213-190,262 C1663.7,12801.6,1569.7,12808.6,1507.7,12788.6z"></path>
      <path d="M227.7,11508.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C383.7,11521.6,289.7,11528.6,227.7,11508.6z"></path>
      <path d="M1507.7,11508.6c-151-50-253-216-222-362c25-119,136-230,254-255c194-41,395,142,375,339c-11,105-90,213-190,262 C1663.7,11521.6,1569.7,11528.6,1507.7,11508.6z"></path>
      <path d="M227.7,10228.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C383.7,10241.6,289.7,10248.6,227.7,10228.6z"></path>
      <path d="M1507.7,10228.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C1663.7,10241.6,1569.7,10248.6,1507.7,10228.6z"></path>
      <path d="M227.7,8948.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C383.7,8961.6,289.7,8968.6,227.7,8948.6z"></path>
      <path d="M1507.7,8948.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C1663.7,8961.6,1569.7,8968.6,1507.7,8948.6z"></path>
      <path d="M227.7,7668.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C383.7,7681.6,289.7,7688.6,227.7,7668.6z"></path>
      <path d="M1507.7,7668.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C1663.7,7681.6,1569.7,7688.6,1507.7,7668.6z"></path>
      <path d="M227.7,6388.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C383.7,6401.6,289.7,6408.6,227.7,6388.6z"></path>
      <path d="M1507.7,6388.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C1663.7,6401.6,1569.7,6408.6,1507.7,6388.6z"></path>
      <path d="M227.7,5108.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C383.7,5121.6,289.7,5128.6,227.7,5108.6z"></path>
      <path d="M1507.7,5108.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C1663.7,5121.6,1569.7,5128.6,1507.7,5108.6z"></path>
      <path d="M227.7,3828.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C383.7,3841.6,289.7,3848.6,227.7,3828.6z"></path>
      <path d="M1507.7,3828.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C1663.7,3841.6,1569.7,3848.6,1507.7,3828.6z"></path>
      <path d="M227.7,2548.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C383.7,2561.6,289.7,2568.6,227.7,2548.6z"></path>
      <path d="M1507.7,2548.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C1663.7,2561.6,1569.7,2568.6,1507.7,2548.6z"></path>
      <path d="M227.7,1268.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C383.7,1281.6,289.7,1288.6,227.7,1268.6z"></path>
      <path d="M1507.7,1268.6c-105-35-200-141-222-248c-43-206,163-412,369-369c155,32,275,190,260,339c-11,105-90,213-190,262 C1663.7,1281.6,1569.7,1288.6,1507.7,1268.6z"></path>
    </g>
  </svg>
);

export default SVGDots;